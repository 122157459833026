<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-mdr"></i> <span class="h5"> Quejas y Reclamos </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">Formularios Completos:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-frown-open fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaQuejas.length}}</span>
                            <br>
                            <span class="text-muted">Quejas y Reclamos registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestion de Quejas y Reclamos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" :to="{name:'Proyectos'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarQuejas = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Queja y Reclamo</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Quejas y Reclamos registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaQuejas" :fields="camposQuejas" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarQuejas(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarQuejas(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(referencias)="data">{{ data.item.referencias | truncate(80)}}</template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarQuejas" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Quejas y reclamos</span>
            </h6>
            <CButtonClose @click="modalRegistrarQuejas = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarQuejas)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="fecha registro" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de registro:" class="mb-2">
                                <b-form-input v-model="datosQuejas.fechaRegistro" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del pasajero" v-model="datosQuejas.nombrePasajero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="guia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Guia Responsable" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el guia responsable" v-model="datosQuejas.guiaResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="caminata" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Caminata" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el camino recorrido" v-model="datosQuejas.caminata"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha caminata" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de caminata:" class="mb-2">
                                <b-form-input v-model="datosQuejas.fechaCaminata" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Tipo:" class="my-2">
                            <b-form-radio-group small plain v-model="datosQuejas.tipo" :options="radioActivo"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="argumento" v-slot="validationContext">
                            <b-form-group label="Argumento:" class="mb-2">
                                <b-form-textarea v-model="datosQuejas.argumento" rows="3" max-rows="6" placeholder="Ingrese argumento" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="acciones" v-slot="validationContext">
                            <b-form-group label="Acciones:" class="mb-2">
                                <b-form-textarea v-model="datosQuejas.acciones" rows="3" max-rows="6" placeholder="Ingrese acciones a tomar" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarQuejas = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarQuejas" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar lote de productos</span></h6>
            <CButtonClose @click="modalActualizarQuejas = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarQuejas)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="fecha registro" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de registro:" class="mb-2">
                                <b-form-input v-model="datosActualizarQuejas.fechaRegistro" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del pasajero" v-model="datosActualizarQuejas.nombrePasajero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="guia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Guia Responsable" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el guia responsable" v-model="datosActualizarQuejas.guiaResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="caminata" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Caminata" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el camino recorrido" v-model="datosActualizarQuejas.caminata"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha caminata" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de caminata:" class="mb-2">
                                <b-form-input v-model="datosActualizarQuejas.fechaCaminata" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Tipo:" class="my-2">
                            <b-form-radio-group small plain v-model="datosActualizarQuejas.tipo" :options="radioActivo"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="argumento" v-slot="validationContext">
                            <b-form-group label="Argumento:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarQuejas.argumento" rows="3" max-rows="6" placeholder="Ingrese argumento" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="acciones" v-slot="validationContext">
                            <b-form-group label="Acciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarQuejas.acciones" rows="3" max-rows="6" placeholder="Ingrese acciones a tomar" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarQuejas = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>

import firebase from 'firebase';
const dbQuejas = firebase.firestore().collection('quejasReclamos');

export default {
    data() {
        return {
            disabled: false,
            listaQuejas: [],
            camposQuejas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombrePasajero",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "guiaResponsable",
                    label: "Guia responsable",
                    class: "text-center",
                },
                {
                    key: "fechaRegistro",
                    label: "Fecha de registro",
                    class: "text-center",
                },
                {
                    key: "fechaCaminata",
                    label: "Fecha de Caminata",
                    class: "text-center",
                },
        {
            key: 'caminata',
            label: 'Caminata',
            class: 'text-center',
        },

                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            radioActivo: [{
                    text: 'Queja o Reclamo',
                    value: 1
                },
                {
                    text: 'Sugerencia',
                    value: 2
                },
                {
                    text: 'Observacion',
                    value: 3
                }

            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosQuejas: {
                idCliente: '',
                nombrePasajero: '',
                guiaResponsable: '',
                caminata: '',
                tipo: 1,
                fechaRegistro: moment().format('YYYY-MM-DD'),
                fechaCaminata: moment().format('YYYY-MM-DD'),
                argumento: '',
                acciones: '',

            },
            datosActualizarQuejas: {
                idQuejas: '',
                idCliente: '',
                nombrePasajero: '',
                guiaResponsable: '',
                tipo: '',
                caminata: '',
                fechaCaminata: moment().format('YYYY-MM-DD'),
                fechaRegistro: moment().format('YYYY-MM-DD'),
                argumento: '',
                acciones: '',

            },

            modalRegistrarQuejas: false,
            modalActualizarQuejas: false,
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        listarQuejas() {
            let me = this;
            dbQuejas
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosQuejas.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaQuejas = [];
                    querySnapshot.forEach((doc) => {
                        me.listaQuejas.push({
                            idQuejas: doc.id,
                            idCliente: doc.data().idCliente,
                            nombrePasajero: doc.data().nombrePasajero,
                            guiaResponsable: doc.data().guiaResponsable,
                            caminata: doc.data().caminata,
                            tipo: doc.data().tipo,
                            fechaCaminata: doc.data().fechaCaminata,
                            fechaRegistro: doc.data().fechaRegistro,
                            argumento: doc.data().argumento,
                            acciones: doc.data().acciones,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaQuejas.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        registrarQuejas() {
            let me = this;
            me.disabled = true;
            dbQuejas.add({
                    idCliente: me.datosQuejas.idCliente,
                    nombrePasajero: me.datosQuejas.nombrePasajero,
                    guiaResponsable: me.datosQuejas.guiaResponsable,
                    caminata:me.datosQuejas.caminata,
                    tipo: me.datosQuejas.tipo,
                    fechaCaminata:me.datosQuejas.fechaCaminata,
                    fechaRegistro: me.datosQuejas.fechaRegistro,
                    argumento: me.datosQuejas.argumento,
                    acciones: me.datosQuejas.acciones,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarQuejas = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        actualizarQuejas() {
            let me = this;
            me.disabled = true;
            dbQuejas.doc(me.datosActualizarQuejas.idQuejas).update({
                    nombrePasajero: me.datosActualizarQuejas.nombrePasajero,
                    caminata: me.datosActualizarQuejas.caminata,
                    tipo: me.datosActualizarQuejas.tipo,
                    guiaResponsable: me.datosActualizarQuejas.guiaResponsable,
                    fechaRegistro: me.datosActualizarQuejas.fechaRegistro,
                    fechaCaminata: me.datosActualizarQuejas.fechaCaminata,
                    argumento: me.datosActualizarQuejas.argumento,
                    acciones: me.datosActualizarQuejas.acciones,
                    

                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarQuejas = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarQuejas(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el guía?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbQuejas.doc(param.item.idQuejas).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirModalActualizarQuejas(param) {
            this.datosActualizarQuejas.idQuejas = param.item.idQuejas;
            this.datosActualizarQuejas.nombrePasajero = param.item.nombrePasajero;
            this.datosActualizarQuejas.caminata = param.item.caminata;
            this.datosActualizarQuejas.tipo = param.item.tipo;
            this.datosActualizarQuejas.guiaResponsable = param.item.guiaResponsable;
            this.datosActualizarQuejas.fechaRegistro = param.item.fechaRegistro;
            this.datosActualizarQuejas.fechaCaminata = param.item.fechaCaminata;
             this.datosActualizarQuejas.argumento = param.item.argumento;
            this.datosActualizarQuejas.acciones = param.item.acciones;

            this.modalActualizarQuejas = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetmodalRegistrarQuejas() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosQuejas.nombrePasajero = '';
            this.datosQuejas.caminata = '';
            this.datosQuejas.argumento = '';
            this.datosQuejas.tipo =1;
            this.datosQuejas.guiaResponsable='';
            this.datosQuejas.fechaRegistro = moment().format('YYYY-MM-DD');
            this.datosQuejas.acciones = '';
            this.datosQuejas.fechaCaminata = moment().format('YYYY-MM-DD');
            this.datosQuejas.observaciones = '';

        },
        resetmodalActualizarQuejas() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarQuejas: function (val) {
            if (val == false) {
                this.resetmodalRegistrarQuejas();
            }
        },
        modalActualizarQuejas: function (val) {
            if (val == false) {
                this.resetmodalActualizarQuejas();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosQuejas.idCliente = this.datosActualizarQuejas.idCliente = user.idCliente;
            this.listarQuejas();
        }
    }

}
</script>